import { RowOption } from "@/components/RowOption"
import { BasicCard } from "@/components/shared/BasicCard/BasicCard"
import { useTitle } from "@/stores/page"
import { FULL_DATE_FORMAT, formatDate } from "@/utils/date"
import {
  Avatar,
  Box,
  Divider,
  NumberFormatter,
  Skeleton,
  Title,
  rem,
} from "@mantine/core"
import { useShallowEffect } from "@mantine/hooks"
import { IconPlus } from "@tabler/icons-react"
import { createFileRoute } from "@tanstack/react-router"
import {
  getBalanceTransactionsControllerFindAllQueryOptions,
  getUsersControllerLoginTelegramQueryOptions,
  useBalanceTransactionsControllerFindAllSuspense,
  useUsersControllerLoginTelegramSuspense,
} from "@vpnox/sdk"
import { Fragment } from "react"

export const Route = createFileRoute("/_dashboard/balance")({
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureQueryData(getUsersControllerLoginTelegramQueryOptions())
    queryClient.ensureQueryData(
      getBalanceTransactionsControllerFindAllQueryOptions(),
    )
  },
  component: BalanceComponent,
})

function BalanceComponent() {
  const { setTitle } = useTitle()

  const { data: user, isLoading: userIsLoading } =
    useUsersControllerLoginTelegramSuspense()
  const { data: transactions, isLoading } =
    useBalanceTransactionsControllerFindAllSuspense()

  useShallowEffect(() => {
    setTitle("Баланс")
  }, [setTitle])

  return (
    <>
      <Title order={2}>История пополнений</Title>
      <BasicCard>
        {/* TODO: List loader or not found items component */}
        {isLoading ? (
          <>
            <Skeleton height={60} radius={0} />
            <Divider />
            <Skeleton height={60} radius={0} />
            <Divider />
            <Skeleton height={60} radius={0} />
            <Divider />
          </>
        ) : (
          transactions?.data?.map(transaction => (
            <Fragment key={transaction.id}>
              <RowOption
                prefix={
                  <Avatar color="green">
                    <IconPlus />
                  </Avatar>
                }
                suffix={
                  <NumberFormatter
                    suffix=" ₽"
                    value={transaction.amount}
                    thousandSeparator=" "
                    style={{
                      fontWeight: 700,
                      fontSize: rem(18),
                    }}
                  />
                }
                label="Пополнение"
                description={formatDate(
                  transaction.createdAt,
                  FULL_DATE_FORMAT,
                )}
              />
            </Fragment>
          ))
        )}
        {user && (
          <Box fz="sm" c="dimmed" ta="center" py="sm">
            Регистрация {formatDate(user.createdAt, FULL_DATE_FORMAT)}
          </Box>
        )}
      </BasicCard>
    </>
  )
}
