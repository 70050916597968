import { RadioCard } from "@/components/inputs/RadioCard"
import { BasicCard } from "@/components/shared/BasicCard"
import { DATE_FORMAT } from "@/utils/date"
import { plural } from "@/utils/plural"
import {
  Alert,
  Box,
  Button,
  Grid,
  NumberFormatter,
  NumberInput,
  Radio,
  Space,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useForm } from "@mantine/form"
import { useDebouncedCallback } from "@mantine/hooks"
import dayjs from "dayjs"
import { zodResolver } from "mantine-form-zod-resolver"
import type React from "react"
import { useState } from "react"
import { z } from "zod"

const BASE_PRICE = 4
const MONTH_PRICE = BASE_PRICE * 30
const MINIMUM_AMOUNT = 100

const data = [
  {
    value: MONTH_PRICE,
    description: "1 месяц",
    bonus: null,
    row: 6,
  },
  // {
  //   value: MONTH_PRICE * 2,
  //   description: "2 месяца",
  //   bonus: null,
  //   row: 6,
  // },
  {
    value: MONTH_PRICE * 3,
    description: "3 месяца",
    bonus: null,
    row: 6,
  },
  // {
  //   value: MONTH_PRICE * 4,
  //   description: "4 месяца",
  //   bonus: null,
  //   row: 6,
  // },
  {
    value: MONTH_PRICE * 6,
    description: "6 месяцев",
    // bonus: "+10%",
    bonus: null,
    row: 6,
  },
  {
    value: MONTH_PRICE * 12,
    description: "12 месяцев",
    // bonus: "+15%",
    bonus: null,
    row: 6,
  },
  {
    value: 0,
    description: "Произвольная сумма",
    bonus: null,
    row: 12,
  },
]

const values = data.map(item => item.value)

const paymentFormSchema = z.object({
  amount: z.coerce
    .number({
      invalid_type_error: "Выберите сумму пополнения, чтобы продолжить",
    })
    .min(4, {
      message: `Минимальная сумма пополнения ${MINIMUM_AMOUNT} рублей`,
    }),
})

export type PaymentFormValues = z.infer<typeof paymentFormSchema>

type PaymentFormProps = {
  onSubmit: (values: PaymentFormValues) => void
  remainingDays: number
  loading?: boolean
}

export const PaymentForm: React.FC<PaymentFormProps> = ({
  onSubmit,
  loading = false,
  ...props
}) => {
  const [option, setOption] = useState(120)
  const formUpdate = useDebouncedCallback((amount: number) => {
    values.includes(amount) ? setOption(amount) : setOption(0)
  }, 300)

  const form = useForm<PaymentFormValues>({
    mode: "controlled",
    initialValues: {
      amount: option,
    },
    validate: zodResolver(paymentFormSchema),
    onValuesChange: ({ amount }) => {
      formUpdate(amount)
    },
  })

  const amountField = form.getInputProps("amount")
  const cards = data.map(item => (
    <Grid.Col key={item.value} span={item.row}>
      <RadioCard value={`${item.value}`}>
        <Stack
          gap={0}
          style={{
            flex: 1,
            lineHeight: 1.25,
          }}
        >
          {item.value > 0 && (
            <NumberFormatter
              suffix=" ₽"
              value={item.value}
              thousandSeparator=" "
              style={{
                fontSize: 18,
                fontWeight: 700,
              }}
            />
          )}
          {/*{item.bonus && (*/}
          {/*  <Badge color="pink" size="md" radius="lg">*/}
          {/*    {item.bonus}*/}
          {/*  </Badge>*/}
          {/*)}*/}
          {!!item.description && <Text size="sm">{item.description}</Text>}
        </Stack>
      </RadioCard>
    </Grid.Col>
  ))
  const days = Math.floor(
    amountField.value >= MINIMUM_AMOUNT ? amountField.value / BASE_PRICE : 0,
  )
  const remainingDays = plural(
    days,
    n =>
      `Хватит на ${n} день, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    n =>
      `Хватит на ${n} дня, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    n =>
      `Хватит на ${n} дней, до ${dayjs()
        .add(n + props.remainingDays, "day")
        .format(DATE_FORMAT)}`,
    `Минимальная сумма пополнения ${MINIMUM_AMOUNT} рублей`,
  )

  return (
    <Box
      component={"form"}
      onSubmit={form.onSubmit(onSubmit)}
      style={{ flex: 1, display: "flex", flexDirection: "column" }}
    >
      <Stack>
        <Alert>
          Вы можете выбрать сумму или ввести ее произвольно в поле вручную
        </Alert>

        <Box>
          <Title order={3}>Сумма пополнения</Title>
          <NumberInput
            {...amountField}
            thousandSeparator=" "
            step={10}
            leftSection={
              <Text fz={24} fw={500}>
                ₽
              </Text>
            }
            rightSection={null}
            hideControls
            styles={{
              input: {
                height: 50,
                fontSize: 24,
              },
            }}
          />
        </Box>

        {!!remainingDays && (
          <Text size="sm" c="dimmed">
            {remainingDays}
          </Text>
        )}

        <Radio.Group
          value={option.toString()}
          onChange={value => {
            const amount = Number.parseInt(value)
            form.setFieldValue(
              "amount",
              !(Number.isNaN(amount) || amount === 0) ? amount : 100,
            )
            setOption(!Number.isNaN(amount) ? amount : 0)
          }}
        >
          <BasicCard p="xs">
            <Grid gutter={2} grow>
              {cards}
            </Grid>
          </BasicCard>
        </Radio.Group>

        {/*<BalanceBonus value={amountField.value} color="pink" />*/}
      </Stack>

      <Space />

      <Stack mt="auto" gap="xs">
        <Button type="submit" loading={loading}>
          Перейти к оплате
        </Button>
        {/*<Text c="dimmed" ta="center" mt="sm">*/}
        {/*  С бонусом будет начислено 1 112 руб*/}
        {/*</Text>*/}
        <Text c="dimmed" size="xs" ta="center">
          После нажатия откроется браузер с выбором способа оплаты. Если у вас
          возникнут проблемы напишите в нашу поддержку.
        </Text>
      </Stack>
    </Box>
  )
}
